import { Button, Input, message, Space, Table } from "antd";
import type { Breakpoint, TableColumnsType } from "antd";
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { h } from "preact";
import React, { useEffect, useState } from "preact/compat";
import { SizeType } from "antd/es/config-provider/SizeContext";
// import useToken from "antd/es/theme/useToken";
import config from "src/config";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import MergeForm from "./MergeForm";
import useFetch from "../use-fetch";
import _ from 'lodash';
import CustomerDeviceTestresultsTable from "./CustomerDeviceTestresultsTable";

interface TableCustomerDevice {
  Geraete_Index: number;
  Geraete_ID: string | null;
  Geraete_Kunden_Index: number;
  Geraete_Bezeichnung: string | null;
  Geraete_Bemerkung: string | null;
  Geraete_Abteilung: string | null;
  Geraete_Pruefablauf: number | null;
  Geraete_Pruefoptionen: number | null;
  Geraete_Pruefdatum: string | number | null;
  Geraete_Pruefablauf_Name: string | null;
  Geraete_Datum_Naechste_Pruefung: string | number | null;
  Geraete_Pruefintervall: number | null;
  Geraete_Nennleistung: number | null;
  Geraete_SN: string | null;
  Geraete_Hersteller: string | null;
  Geraete_Pruefablauf_Admin_Index: number | null;
  Geraete_Pruefablauf_Customer_Index: number | null;
  Aenderungsdatum: string | number | null;
  Geraete_VDE: number | null;
  Geraete_SK: number | null;
  Geraete_Fragen: string | null;
  Geraete_Zusatz_Pruefungen: number | null;
  departmentId: number | null;
  manufacturerId: number | null;
  Geraete_Sichtpruefung: number | null;
  ThresholdListID: number | null;
  Geraete_Pruefablauf_Index: number | null;
  Geraete_Leitungslaenge: number | null;
  Geraete_Anz_Leiter: number | null;
  Geraete_Leitungsquerschnitt: number | null;
  Geraete_U_Ausgang: number | null;
  Gebaeude: string | null;
  Geraete_Zeit_rsl: number | null;
  Geraete_Zeit_riso: number | null;
  Geraete_Zeit_iabl: number | null;
  Geraete_Zeit_pabl: number | null;
  Geraete_Zeit_iber: number | null;
  Geraete_Zeit_iberausg: number | null;
  Geraete_Zeit_funkt: number | null;
  Geraete_Zeit_pelv: number | null;
  Geraete_Zeit_schw: number | null;
  Geraete_Zeit_prcd: number | null;
  Geraete_Zeit_kabel: number | null;
  Geraete_Zeit_umpolung: number | null;
  AusserBetrieb: boolean | null;
  Geraete_Typ: string | null;
  Geraete_Modell: string | null;
  Geraete_Private_Anschaffung: boolean | null;
  Geraete_Vorlagen_ID: number | null;
  Geraete_VorlButtZeile1: string | null;
  Geraete_VorlButtZeile2: string | null;
  Geraete_VorlButtZeile3: string | null;
  Geraete_VorlButtZeile4: string | null;
  Geraete_Vorlagen_Farbe: string | null;
  Geraete_Pruefablauf_2: number | null;
  Geraete_EV_Pruefablauf: number | null;
  Geraete_EV_Fehlertest: number | null;
  manufacturerName: string | null;
  testResultsCount?: number;
}

const colums: TableColumnsType<TableCustomerDevice> = [
  {
    title: "ID",
    dataIndex: "Geraete_Index",
    key: "Geraete_Index",
    responsive: ["xxl"],
  },
  { title: "Raum", dataIndex: "Geraete_Abteilung", key: "Geraete_Abteilung", sorter: (a, b) => (a.Geraete_Abteilung ?? '').localeCompare(b.Geraete_Abteilung ?? ''), },

  { title: "GeräteID", dataIndex: "Geraete_ID", key: "Geraete_ID", sorter: (a: TableCustomerDevice, b: TableCustomerDevice) => (a.Geraete_ID ?? '').localeCompare(b.Geraete_ID ?? ''), },
  { title: "SN", dataIndex: "Geraete_SN", key: "Geraete_SN" },
  { title: "Hersteller", dataIndex: "manufacturerName", key: "manufacturerName", sorter: (a, b) => (a.manufacturerName ?? '').localeCompare(b.manufacturerName ?? ''), },
  ...[
    "Geraete_Bezeichnung",
    "Geraete_Bemerkung",
    "Geraete_Pruefablauf_Name",
  ].map((key: string) => ({
    title: key
      .split("_")
      .filter((s) => ["geraete"].indexOf(s.toLocaleLowerCase()) === -1)
      .join(" "),
    dataIndex: key,
    key,
    responsive: ["lg"] as Breakpoint[],
    sorter: (a: TableCustomerDevice, b: TableCustomerDevice) => (_.get(a, key, '')).localeCompare(_.get(b, key, '')),
  })),
  // { title: "Geändert", dataIndex: "Aenderungsdatum", key: "Aenderungsdatum", responsive: ["lg"] },
  // {
  //   title: "",
  //   key: "action",
  //   render: (_, record) => (
  //     null
  //   ),
  // },
];

const CustomerDevicesTable: React.FC<{ size: SizeType; customer: string }> = ({ size, customer }) => {
  const { data, error, loading, fetchData } = useFetch(`/extract/customer/${customer}/devices`);
  // const designToken = useToken()[1];
  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  return (
    <Table<TableCustomerDevice>
      loading={loading}
      size={size}
      columns={colums}
      dataSource={data?.sort((a, b) => (a.Geraete_Abteilung ?? '').localeCompare(b.Geraete_Abteilung ?? ''))}
      expandable={{
        expandedRowRender: (doc) => (
          <div style={{ paddingBottom: 12 }}>{doc.Geraete_ID && <CustomerDeviceTestresultsTable size={size} deviceId={doc.Geraete_ID} />}</div>
        ),
        rowExpandable: (record) => (record.testResultsCount ?? 0) > 0,
        expandRowByClick: true,
      }}
      pagination={(data ?? []).length > 10 ? undefined : false}
      rowKey={(doc: TableCustomerDevice) => doc.Geraete_ID}
    />
  );
};

export default CustomerDevicesTable;

import { Button, Card, Descriptions, Flex, Input, message, Space, Table } from "antd";
import type { Breakpoint, TableColumnsType } from "antd";
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { h } from "preact";
import React, { useEffect, useState } from "preact/compat";
import { SizeType } from "antd/es/config-provider/SizeContext";
// import useToken from "antd/es/theme/useToken";
import config from "src/config";
import useFetch from "../use-fetch";
import _ from 'lodash';
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";

interface Procedure {
  "procName": string;
  "id": number;
  "bitMask": number;
  "bitMask_2": number;
  "sequenceId": number;
  "success": number;
}

interface ProcedureValue {
  "id": number;
  "ident": string;
  "isValid": number;
  "sqProcId": number;
  "value": number;
  "valueStr": string;
}

const ProcedureValues: React.FC<{ size: SizeType; procedureId: number }> = ({ size, procedureId }) => {
  const { data, error, loading, fetchData } = useFetch(`/extract/procedure/${procedureId}/values`);

  if (!data) {
    return null;
  }

  const d = data as ProcedureValue[];

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  return (
    <div>
      <Descriptions layout="vertical" items={d.map(pv => ({
        key: `${pv.id}`,
        label: pv.ident,
        children: pv.valueStr,
      }))} />
    </div>
  );
};

const CustomerTestresultProcedures: React.FC<{ size: SizeType; sequenceId: number }> = ({ size, sequenceId }) => {
  const { data, error, loading, fetchData } = useFetch(`/extract/testresult/${sequenceId}/procedures`);
  // const designToken = useToken()[1];

  if (!data) {
    return null;
  }

  const d = data as Procedure[];

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  return (
    <Flex gap={12}>
      {d.map(s =>
        <Card title={s.procName} extra={s.success ? <CheckCircleOutlined style={{ color: 'green' }} /> : <WarningOutlined style={{ color: 'red' }} />} style={{ flex: 1 }}>
          <ProcedureValues size={size} procedureId={s.id} />
        </Card>
      )}

    </Flex>
  );
};

export default CustomerTestresultProcedures;
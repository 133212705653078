import { Button, Input, message, Space, Table } from "antd";
import type { Breakpoint, TableColumnsType } from "antd";
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { h } from "preact";
import React, { useEffect, useState } from "preact/compat";
import { SizeType } from "antd/es/config-provider/SizeContext";
// import useToken from "antd/es/theme/useToken";
import config from "src/config";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import MergeForm from "./MergeForm";
import useFetch from "../use-fetch";
import CustomerDevicesTable from "./CustomerDevicesTable";

interface TableCustomer {
  Aenderungsdatum: string;
  Ansprechpartner_Abteilung: string;
  Ansprechpartner_Email: string;
  Ansprechpartner_Rufnummer: string;
  Kunden_Ansprechpartner: string;
  Kunden_Bemerkung: string;
  Kunden_Email: string;
  Kunden_Fax: string;
  Kunden_Index: number;
  Kunden_Name: string;
  Kunden_Nummer: string;
  Kunden_PLZ: string;
  Kunden_Rufnummer: string;
  Kunden_Strasse: string;
  Kunden_Wohnort: string;
}

const colums: TableColumnsType<TableCustomer> = [
  {
    title: "ID",
    dataIndex: "Kunden_Index",
    key: "Kunden_Index",
    responsive: ["lg"],
  },
  { title: "Kundennr.", dataIndex: "Kunden_Name", key: "Kunden_Name" },
  { title: "Name", dataIndex: "Kunden_Ansprechpartner", key: "Kunden_Ansprechpartner" },
  ...[
    // "Kunden_Nummer",
    "Kunden_PLZ",
    "Kunden_Strasse",
    "Kunden_Wohnort",
    // "Ansprechpartner_Abteilung",
    // "Ansprechpartner_Email",
    // "Ansprechpartner_Rufnummer",
    "Kunden_Bemerkung",
    // "Kunden_Fax",
    // "Kunden_Email",
    // "Kunden_Rufnummer",
    // "Aenderungsdatum",
  ].map((key) => ({
    title: key
      .split("_")
      .filter((s) => ["kunden", "ansprechpartner"].indexOf(s.toLocaleLowerCase()) === -1)
      .join(" "),
    dataIndex: key,
    key,
    responsive: ["lg"] as Breakpoint[],
  })),
  // { title: "Geändert", dataIndex: "Aenderungsdatum", key: "Aenderungsdatum", responsive: ["lg"] },
  {
    title: "",
    key: "action",
    render: (_, record) => (
      <Space size="middle" onClick={(e) => e.stopPropagation()}>
        {/* @TODO: Check is numeric */}
        {record.Kunden_Name && (
          <Button icon={<DownloadOutlined />} href={`${config.api}/extract/customer/${record.Kunden_Name}`} />
        )}
      </Space>
    ),
  },
];

const CustomerTable: React.FC<{ size: SizeType }> = ({ size }) => {
  const { data, error, loading, fetchData } = useFetch("/extract/customers");
  // const designToken = useToken()[1];
  const [filterValue, setFilterValue] = useState<string>();

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue((e.target as HTMLInputElement).value);
  };

  const filterFn = (c: TableCustomer) => {
    if (!filterValue) {
      return true;
    }

    return (
      [c.Kunden_Name, c.Kunden_Nummer, c.Kunden_Ansprechpartner, c.Kunden_PLZ, c.Kunden_Strasse, c.Kunden_Wohnort]
        .map((k) => String(k).toLocaleLowerCase())
        .join("_")
        .indexOf(filterValue.toLocaleLowerCase()) > -1
    );
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }} className="gerl-customer-table">
      <div style={{ maxWidth: 320 }}>
        <Input placeholder="Search" allowClear onChange={onChange} size={size} prefix={<SearchOutlined />} />
      </div>
      <Table<TableCustomer>
        loading={loading}
        size={size}
        columns={colums}
        dataSource={data ? data.filter(filterFn) : data}
        expandable={{
          expandedRowRender: (doc) => (
            <CustomerDevicesTable size={size} customer={doc.Kunden_Nummer} />
          ),
          expandRowByClick: true,
        }}
        footer={() => (data && data.length ? <MergeForm size={size} onMerged={() => fetchData()} /> : null)}
        rowKey={(doc) => doc.Kunden_Nummer}
      />
    </Space>
  );
};

export default CustomerTable;

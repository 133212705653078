import { Button, Input, message, Space, Table } from "antd";
import type { Breakpoint, TableColumnsType } from "antd";
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { h } from "preact";
import React, { useEffect, useState } from "preact/compat";
import { SizeType } from "antd/es/config-provider/SizeContext";
// import useToken from "antd/es/theme/useToken";
import config from "src/config";
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import MergeForm from "./MergeForm";
import useFetch from "../use-fetch";
import _ from 'lodash';
import CustomerTestresultProcedures from "./CustomerTestresultProcedures";


interface TableCustomerDeviceTestresult {
  deviceId: number;
  id: number;
  remark: string;
  startDate: string;
  success: number;
  testResultId: number;
  typeId: number;
  viewTestOk: number;
}

const testTypes = [
  'Eingangsprüfung',
  'Instandsetzung',
  'Änderung',
  'Wiederholungsprüfung',
];

const colums: TableColumnsType<TableCustomerDeviceTestresult> = [
  // { title: "GeräteID", dataIndex: "deviceId", key: "deviceId" },
  { title: "Typ", dataIndex: "typeId", key: "typeId", render: (value: number) => testTypes[value - 1], sorter: (a, b) => a.typeId - b.typeId },
  { title: "Datum", dataIndex: "startDate", key: "startDate", sorter: (a, b) => a.startDate.localeCompare(b.startDate) },
  { title: "Notiz", dataIndex: "remark", key: "remark", responsive: ["lg"] },
  { title: "Ergebnis", dataIndex: "success", key: "success", render: (value) => value ? <CheckCircleOutlined style={{ color: 'green' }} /> : <WarningOutlined style={{ color: 'red' }} /> },
  { title: "Sichtprüfung", dataIndex: "viewTestOk", key: "viewTestOk", render: (value) => value ? <CheckCircleOutlined style={{ color: 'green' }} /> : <WarningOutlined style={{ color: 'red' }} /> },
];

const CustomerDeviceTestresultsTable: React.FC<{ size: SizeType; deviceId: string }> = ({ size, deviceId }) => {
  const { data, error, loading, fetchData } = useFetch(`/extract/device/${deviceId}/testresults`);
  // const designToken = useToken()[1];
  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  return (
    <Table<TableCustomerDeviceTestresult>
      loading={loading}
      size={size}
      columns={colums}
      dataSource={data?.sort((a, b) => a.startDate.localeCompare(b.startDate))}
      expandable={{
        expandedRowRender: (doc) => (
          <div>
            <CustomerTestresultProcedures size={size} sequenceId={doc.id} />
          </div>
        ),
        expandRowByClick: true,
      }}
      pagination={(data ?? []).length > 10 ? undefined : false}
      rowKey={(doc) => doc.id}
    />
  );
};

export default CustomerDeviceTestresultsTable;
